<template>
	<div class="product-carousel-container">
		<div class="product-carousel-container-images">
			<MainSwiper :slidesPerView="1" :data="productPhotos" :breakpoints="breakpoints" :loop="false">
				<template #default="{ item }">
					<inner-image-zoom
						class="product-carousel-container-image"
						:src="item"
						:zoomSrc="item"
						:zoomPreload="true"
						:zoomScale="1.2"
						zoomType="hover"
					/>
				</template>
			</MainSwiper>
		</div>
	</div>
</template>
<script>
import AppImg from "../../shared-components/AppImg";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import MainSwiper from "../../ui/MainSwiper.vue";
export default {
	name: "ProductPreviewCarousel",
	props: {
		list: {
			type: String,
			default: "",
		},
	},
	components: { AppImg, "inner-image-zoom": InnerImageZoom, MainSwiper },
	data() {
		return {
			pagination: {
				clickable: true,
			},
			breakpoints: {
				1: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
			},
			selectedImage: 0,
			activeSlide: 0,
			slider: null,
			baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
		};
	},
	methods: {
		next() {
			if (this.slider) {
				this.slider.go("+1");
			}
		},
		previous() {
			if (this.slider) {
				this.slider.go("-1");
			}
		},
		async init() {
			const refSlider = this.$refs["slider"];
			const Splide = await SplideJS();
			const slider = new Splide.default(refSlider, this.options);
			slider.mount();
			slider.on("move", (newIndex) => {
				this.activeSlide = newIndex;
			});
			this.slider = slider;
		},
		setDefault(e) {
			this.activeSlide = e.currentSlide;
		},
		setMain(image) {
			this.activeSlide = this.productPhotos.findIndex((img) => img === image);
		},
	},
	computed: {
		productPhotos() {
			const photos = [];
			if (this.list) {
				let correctFormat = this.list.replaceAll(";", "|");
				let separator = correctFormat.includes("|") ? "|" : ";";
				correctFormat.split(separator).forEach((image) => {
					if (image && image !== "null") {
						photos.push(this.baseUrl + image);
					}
				});
			}
			return photos;
		},
	},
	watch: {
		activeSlide: function (val) {
			console.log(val);
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .swiper-slide {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 500px;
}
.product-carousel-container-images-list {
	width: 100%;
	height: 500px;
	.product-carousel-container-images-list-slider {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.product-carousel-container-image {
		height: 100%;
		display: inline-block;
		aspect-ratio: 1/1;
	}
}
::v-deep figure,
picture,
img {
	height: 100% !important;
}
::v-deep figure {
	div {
		height: 100% !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		// img {
		// 	height: 600px !important;
		// }
	}
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}
.product-carousel-container {
	width: 600px;
}
.preview-image {
	height: 100px;
}
</style>

