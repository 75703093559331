<template>
	<div class="modalForSms">
		<div class="modal_card">
			<div class="mb-10 modal_card_close">
				<span class="a-btn p-2" style="color: black" theme="white" @click="close"> x </span>
			</div>
			<div class="mb-20">
				<slot name="danger" />
			</div>
			<div>
				<div>{{ $t("typeSms") }}</div>
				<form-label @enterFromInputLabel="check" :is-invalid="$v.code.$error" class="mt-5" v-model="code" />
			</div>
			<div class="mt-10 d-flex" style="justify-content: flex-end">
				<app-button
					theme="gray-light"
					class="mr-10"
					sides="20"
					height="40"
					font-size="14"
					weight="700"
					@click="clear"
					>{{ $t("clear") }}</app-button
				>
				<app-button
					theme="main"
					class="mr-10"
					sides="20"
					height="40"
					font-size="14"
					weight="700"
					@click="check"
					>{{ $t("send") }}</app-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import AppButton from "./AppButton.vue";
import FormLabel from "./FormLabel.vue";
export default {
	components: {
		FormLabel,
		AppButton,
	},
	validations: {
		code: {
			required,
		},
	},
	data: () => ({
		code: "",
	}),
	methods: {
		close() {
			this.$emit("closeModal");
		},
		clear() {
			this.$v.code.$reset();
			this.code = "";
		},
		check() {
			this.$v.code.$touch();
			if (this.$v.code.$invalid) return;
			this.$emit("closeModal");
			this.$emit("sms", this.code);
			this.code = "";
			this.$v.code.$reset();
		},
	},
};
</script>

<style lsng="scss" scoped>
.modalForSms {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999999;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	.modal_card {
		min-width: 400px;
		max-width: 600px;
		background: #fff;
		padding: 5px 20px 20px 20px;
		border-radius: 10px;
	}
	.modal_card_close {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
}
</style>

