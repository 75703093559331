import Vue from 'vue';

export default {
    url: '/api-company/Company/',
    api: Vue.prototype.$api,

    getListAccount() {
        return this.api.get(`${this.url}GetListAccount`);
    },

    getPlanSchedule(id) {
		return this.api.get(`${this.url}GetByPlanSchedule?id=${id}`)
	},
}