<!--todo: here is bug with activeSlide-->
<template>
  <div class="product-preview">
    <app-img class="product-preview__image mb-20" :src="productPhotos[activeSlide].image" :alt="title"/>
    <div class="product-preview__slider">
      <div @click="previous" class="product-preview__button">
        <img src="../../../assets/icons/chevron-left.svg" alt="">
      </div>
      <div class="product-preview__thumb-list splide" ref="slider">
        <div class="splide__slider">
          <div class="splide__track">
            <div class="splide__list">
              <div :key="idx" class="splide__slide" v-for="(item, idx) in productPhotos">
                <div class="product-preview__thumb" :class="{'product-preview__thumb--active': idx === activeSlide}">
                  <div class="product-preview__thumb-wrap">
                    <app-img :alt="title" :src="item.thumb"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-preview__button product-preview__button--next" @click="next">
        <img src="../../../assets/icons/chevron-left.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
    import AppImg from '../../shared-components/AppImg';

    const SplideJS = () => import("@splidejs/splide");
    import "@splidejs/splide/dist/css/splide-core.min.css";

    export default {
        components: {AppImg},
        props: {
            title: String,
            list: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                activeSlide: 0,
                slider: null,
                baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN
            }
        },
        computed: {
          productPhotos() {
            const photos = [];
            if (this.list) {
              (this.list.split(";")).forEach(image => {
                if (image && image !== 'null') {
                  photos.push({
                          thumb: this.baseUrl + image,
                          image: this.baseUrl + image
                        })
                }
              });
            }
            return photos;
          },
            options() {
                return {
                    type: 'loop',
                    drag: false,
                    perPage: 4,
                    perMove: 1,
                    gap: 15,
                    arrows: false,
                    pagination: false,
					// direction  : 'ttb',
					// heightRatio: 0.6,
                };
            }
        },
        methods: {
            next() {
                if (this.slider) {
                    this.slider.go("+1");
                }
            },
            previous() {
                if (this.slider) {
                    this.slider.go("-1");
                }
            },
            async init() {
                const refSlider = this.$refs["slider"];
                const Splide = await SplideJS();
                const slider = new Splide.default(refSlider, this.options);
                slider.mount();
                slider.on("move", newIndex => {
                    this.activeSlide = newIndex;
                });
                this.slider = slider;
            }
        },
        mounted() {
            this.init();
        },
        watch: {
            list() {
                this.init();
            }
        },
        beforeDestroy() {
            if (this.slider) {
                const slider = this.slider;
                setTimeout(() => {
                    slider.destroy();
                }, 150);
            }
        }
    }
</script>
<style lang="scss" scoped>
  .product-preview {
    &__slider {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__thumb {
      background: #FFFFFF;
      border: 1px solid #DFEAF3;
      border-radius: 4px;
      width: 100%;

      &-wrap {
        padding-top: 100%;
        position: relative;
      }

      &--active {
        border-color: var(--color-main);
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 30px);
        max-height: calc(100% - 30px);
      }
    }

    &__thumb-list {
      margin-left: 10px;
      margin-right: 10px;
      width: calc(100% - 88px);
    }

    &__button {
      margin-left: -10px;
      padding: 10px;
      color: var(--color-text-light);
      cursor: pointer;
      user-select: none;

      &:hover {
        color: var(--color-main);
      }

      &--next {
        margin-left: 0;
        margin-right: -10px;

        svg, img {
          transform: rotate(180deg);
        }
      }
    }

    &__image {
      height: 422px;
      object-fit: contain;
      overflow: hidden;
    }
  }

  @media (max-width: 500px) {
    .product-preview__image {
      height: 300px;
    }

    .product-preview__thumb {
      padding: 10px;

      img {
        max-width: 130px !important;
        max-height: 130px !important;
        object-fit: contain;
      }
    }
  }
</style>
