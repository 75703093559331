<template>
	<nav class="breadcrumb">
		<ul class="breadcrumb__list">
			<li v-for="(item, index) in items" :key="index" class="breadcrumb__list-item">
				<router-link
					:to="item.url"
					:class="{
						'breadcrumb__list-item__active': currentRoute === item.url,
					}"
					>{{ item.label }}</router-link
				>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	props: {
		items: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		currentRoute() {
			return this.$route.path;
		},
	},
};
</script>

<style scoped lang="scss">
.breadcrumb {
	&__list {
		display: flex;
		align-items: center;
		&-item {
			list-style: none;
			display: inline-block;
			margin-right: 5px;
			&__active {
				border-bottom: 2px solid transparent;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					bottom: -2px;
					width: 100% !important;
					height: 2px;
					background-color: #39537a;
				}
			}
			&:not(:last-child)::after {
				content: "\00BB";
				margin-left: 5px;
				color: #39537a;
			}
			a {
				text-decoration: none;
				color: #324a52;
				position: relative;
				&::after {
					content: "";
					position: absolute;
					left: 0;
					bottom: -2px;
					width: 0;
					height: 2px;
					background-color: #39537a;
					transition: width 0.5s ease;
				}
				&:hover::after {
					width: 100%;
				}
				&:hover {
					color: #39537a;
				}
			}
		}
	}
}
</style>
