import Vue from "vue";

export default {
	auctionUrl: "/api-company/Company/",
	api: Vue.prototype.$api,

	async getListPlanProducts() {
		let params = { from: 1, to: 12, skip: -1, statusId: 3 };
		return await this.api.get(`${this.auctionUrl}GetCompanyPlanSchedules`, { params });
	},
};

