<template>
	<main>
		<div class="bg-white">
			<div class="container">
				<header class="mb-20 py-30">
					<div class="page-breadcrumb py-10" v-if="breadCrumbList()">
						<app-breadcrumb :items="breadCrumbList()" />
					</div>
					<div class="container-inner">
						<div class="d-flex flex-column">
							<!-- <app-text
								class="header-title color-text-light mb-5"
								size="14"
								weight="400"
								line-height="16"
								tag="h2"
								>{{ product.productName | translate }}
							</app-text> -->
							<app-text class="header-title color-secondary-dark" size="20" line-height="23" tag="h2"
								>{{ product.productName | translate }}
							</app-text>
						</div>
					</div>
					<!--	<app-text size="14" class="text-underline color-main mb-5" line-height="18" tag="h2">
							  &nbsp / {{ product.productName | translate }}
						  </app-text>
					-->
				</header>
			</div>
		</div>

		<div class="container">
			<div class="product-info flex-nowrap" :class="isMobileMedium ? 'mb-40' : 'mb-60'">
				<div class="imgContainerPreview">
					<ProductPreviewCarouselVue :list="product.photos"></ProductPreviewCarouselVue>
					<div class="tabContent">
						<div class="tabHeaders">
							<div
								v-for="(tab, index) in tabOptions"
								:class="{ active: tab.active }"
								@click="setTab(index)"
							>
								{{ tab.title }}
							</div>
						</div>
						<template v-if="characteristicsOfTab[0].active">
							<div
								class="tabContentWrapper"
								style="user-select: none"
								onmousedown="return false;"
								onselectstart="return false;"
							>
								<div v-if="product && product.technicalSpecifications">
									<template v-if="product.technicalSpecifications[$i18n.locale]">
										{{ product.technicalSpecifications[$i18n.locale] }}
									</template>
									<template v-else>
										{{ product.technicalSpecifications.uz }}
									</template>
								</div>
								<div v-if="product && !product.technicalSpecifications">
									{{ $t("noData") }}
								</div>
							</div>
						</template>
						<template v-if="characteristicsOfTab[1].active">
							<div
								class="tabContentWrapper"
								style="user-select: none"
								onmousedown="return false;"
								onselectstart="return false;"
							>
								<div v-if="product && product.functionalCharacteristics">
									{{ product.functionalCharacteristics }}
								</div>
								<div v-if="product && !product.functionalCharacteristics">
									{{ $t("noData") }}
								</div>
							</div>
						</template>
					</div>
				</div>

				<section class="product-info__actions bg-white">
					<div class="product-info__head">
						<div class="product-info__title">
							<app-text class="mb-25" size="18" line-height="17" weight="600"
								>{{ $t("generalCharacteristics") }}:</app-text
							>
							<img
								v-if="product.hasDisabilityInCompany"
								src="@/assets/icons/shop_img1.svg"
								alt=""
								class="mb-25"
							/>
						</div>

						<div class="d-flex">
							<div
								class="d-flex flex-column"
								style="user-select: none"
								onmousedown="return false;"
								onselectstart="return false;"
							>
								<div class="characteristics">
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("minMaxParty") }}:
										<span
											>{{ product.minPart }}-{{ product.maxPart }} {{ product.measureName }}</span
										>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("offerUntilActive") }}:
										<span>{{ product.publicEndDate | moment("DD.MM.YYYY HH:mm:ss") }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("deliveryTime") }}:
										<span>{{ product.deliveryDay }} {{ shelfLifeTypes[0].name }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("sertificate") }}:
										<span>
											{{ product.isCertificate ? $t("certificated") : $t("notCertificated") }}
										</span>
									</app-text>
									<app-text
										class="color-text-light pt-0 mb-5"
										size="14"
										line-height="18"
										v-if="checkBrand(product.brand)"
									>
										{{ $t("brand") }}:
										<span>{{ product.brand | translate }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("manufacturerCountry") }}:
										<span>{{ product.countryName | translate }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("shelfLife") }}:
										<span v-if="product.expirationDayMonth">
											{{ product.expirationDayMonth }}
											{{ shelfLifeTypes[product.expirationType - 1].name }}</span
										>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("warrantyPeriod") }}:
										<span>{{ product.warrantyPeriod }} {{ shelfLifeTypes[0].name }}</span>
									</app-text>
									<app-text
										class="color-text-light pt-0 mb-5"
										size="14"
										line-height="18"
										v-if="product && product.productDate"
									>
										{{ $t("prodYearAndMonth") }}:
										<span>{{ getMonthNameAndYear(product.productDate) | translate }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("license") }}:
										<span>{{ product.isLicense ? $t("thereIs") : $t("no") }}</span>
									</app-text>

									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("epFindings") }}:
										<span>{{ product.isEpidemiological ? $t("thereIs") : $t("no") }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("verOp") }}:
										<span>{{ product.isVeteran ? $t("thereIs") : $t("no") }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("phyConc") }}:
										<span>{{ product.isSanitary ? $t("thereIs") : $t("no") }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("vetSan") }}:
										<span>{{ product.isVeteranSanitar ? $t("thereIs") : $t("no") }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("enAssessment") }}:
										<span>{{ product.isEcological ? $t("thereIs") : $t("no") }}</span>
									</app-text>

									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("documentsTransferredWithTheGoods") }}:
										<span>{{ product.listDocumentation }}</span>
									</app-text>
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
										{{ $t("warrantyTerms") }}:
										<span>{{ product.warrantyTerms | translate }}</span>
									</app-text>
									<template v-if="product.hasDisabilityInCompany">
										<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
											{{ $t("organizationName") }}:
											<span>{{ product.company.name | translate }}</span>
										</app-text>
										<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18">
											{{ $t("inn") }}:
											<span>{{ product.company.tin }}</span>
										</app-text>
										<app-text
											v-if="product.company.phones"
											class="color-text-light pt-0 mb-5"
											size="14"
											line-height="18"
										>
											{{ $t("phone") }}:
											<span>{{ product.company.phones }}</span>
										</app-text>
									</template>
									<app-text
										class="color-text-light pt-0 mb-5"
										size="14"
										line-height="18"
										v-if="product.companyTnvedName && product.companyTnVedCode"
									>
										{{ $t("tvedCode") }}:
										<span
											v-if="product.companyTnvedName && product.companyTnVedCode"
											class="tnvedCoed"
										>
											<app-button-a theme="gray-light" class="mt-10" @click="tnvedModal = true">
												{{ product.companyTnVedCode }}
											</app-button-a>
										</span>
									</app-text>
									<app-text
										class="color-text-light pt-0 mb-5"
										size="14"
										line-height="18"
										v-else-if="product.companyEnktName && product.companyEnktCode"
									>
										{{ $t("enktCode") }}:
										<span
											v-if="product.companyEnktName && product.companyEnktCode"
											class="tnvedCoed"
										>
											<app-button-a theme="gray-light" class="mt-10">
												{{ product.companyEnktCode }}
											</app-button-a>
										</span>
									</app-text>
									<div v-for="property in product.enktProperties" :key="property.id">
										<app-text
											class="color-text-light pt-0 mb-5"
											size="14"
											line-height="18"
											v-if="checkProperties(property.options)"
										>
											{{ property.label | translate }}:
											<span>
												{{ getSelectedOption(property.options) | translate }}
											</span>
										</app-text>
									</div>
								</div>
							</div>

							<div class="d-flex flex-column product-info__box ml-80">
								<app-content-card theme="light" padding-y="20" :padding-x="isMobileMedium ? 15 : 30">
									<app-text class="color-text-light pt-0 mb-5" size="14" line-height="18"
										>№ {{ product.offerNumber }}
									</app-text>
									<app-text class="color-text-light mb-5" size="14" line-height="14">
										<span class="textSalToqroq">{{ $t("unitPrice") }}</span></app-text
									>
									<app-text class="mb-15" size="24" line-height="31" weight="600">
										{{ priceCorrecter(product.unitPrice) }} {{ $t("sum") }}
									</app-text>
									<template>
										<a v-if="!$store.getters.isLoggedIn" :href="`${adminUrl}/#/login`">
											<app-button
												theme="linear"
												class="w-100 font_Hsemibold mb-15"
												height="40"
												:font-size="isMobile ? '12' : '14'"
												>{{ $t("signInOrder") }}
											</app-button>
										</a>
										<app-button
											v-if="
												$store.getters.isLoggedIn &&
												product &&
												product.companyId &&
												$store.state.userInfo &&
												typeof $store.state.userInfo === 'object' &&
												canRequest
											"
											theme="linear"
											class="w-100 font_Hsemibold mb-15"
											:font-size="isMobile ? '12' : '14'"
											@click="openModal()"
											:disabled="Boolean(!canRequest || workingTime)"
											>{{
												product.hasDisabilityInCompany
													? $t("direct_contract")
													: $t("requestPrice")
											}}
										</app-button>
										<!-- <app-button
											v-if="
												$store.getters.isLoggedIn &&
												product &&
												product.companyId &&
												$store.state.userInfo &&
												typeof $store.state.userInfo === 'object' &&
												canRequest &&
												!getStructureId
											"
											theme="linear"
											class="w-100 font_Hsemibold mb-15"
											@click="openModal2()"
											:font-size="isMobile ? '12' : '14'"
											:disabled="Boolean(!canRequest || workingTime)"
											>{{ $t("requestPrice") }}
										</app-button> -->
										<app-text
											v-if="
												$store.getters.isLoggedIn &&
												product &&
												product.companyId &&
												$store.state.userInfo &&
												typeof $store.state.userInfo === 'object' &&
												!canRequest
											"
											class="mb-10"
										>
											{{ $t("notRequestPrice") }}
										</app-text>
									</template>
									<block-wrap offset-x="20" width-auto mod-style="justify-between">
										<div :class="{ flexBetween: product.isAutoRenewalOffer }">
											<app-text class="color-text-light mb-5" size="14" line-height="18"
												>{{ $t("count") }}
											</app-text>
											<app-text size="14" line-height="18" weight="500">
												{{ product.productQuantity }}
												{{ product.measureName }}
											</app-text>
										</div>
										<div>
											<app-text class="color-text-light mb-5" size="14" line-height="18"
												>{{ $t("expirationDate") }}
											</app-text>
											<app-text size="14" line-height="18" weight="500"
												>{{ product.publicEndDate | moment("DD.MM.YYYY HH:mm") }}
											</app-text>
										</div>
									</block-wrap>
								</app-content-card>
								<!-- <app-text
									v-if="url === 'https://new.cooperation.uz/ocelot/'"
									class="color-text-light mt-30"
									size="14"
									line-height="17"
									weight="400"
								>
									{{ "* " + $t("mechanismQuoteRequestNotAvailable") }}
								</app-text> -->
							</div>

							<div class="product-info__regions ml-20">
								<app-text class="mb-10 color-text-light" size="14" line-height="30" weight="400"
									>{{ product.isSelfDelivery ? $t("pickup") : $t("deliveryRegions") }}:
								</app-text>
								<div class="regions-list">
									<template>
										<template v-for="region in deliveriesList">
											<div style="margin-bottom: 20px">
												<div v-for="district in region" class="region">
													{{ district.regionName | translate }}
													:
													{{ district.districtName | translate }}
												</div>
											</div>
										</template>
										<template v-if="product.offerDeliveryList.length > 10">
											<a class="link-blue cursor-pointer" @click="isMinimum = !isMinimum">
												{{ isMinimum ? "Развернуть" : "Свернуть" }}
											</a>
										</template>
									</template>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>

			<section class="section" :class="isMobileSmall ? 'pb-50' : 'pb-80'">
				<div class="section__wrapper">
					<div class="section__top" :class="isMobileSmall ? 'mb-30' : 'mb-50'">
						<div class="section__top-left">
							<app-text
								:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
								weight="700"
								:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
								v-if="similarProducts.length > 0"
								>{{ $t("relativeProducts") }}
							</app-text>
						</div>
						<div class="section__top-right">
							<router-link to="/product-list" class="color-text-light d-flex align-center"
								>{{ $t("showAll") }} >>
							</router-link>
						</div>
					</div>
					<div class="section__body">
						<block-wrap
							:count="isMobileSmall ? 1 : isDesktopSmall ? 2 : isDesktopMedium ? 3 : 4"
							offset-y="16"
							offset-x="16"
						>
							<a
								v-for="(item, idx) in similarProducts"
								:key="idx"
								:href="`/#/product-list/${item.offerNumber}`"
								target="_blank"
							>
								<app-product-card :key="idx" :product="item" @change="getProductById($event)" />
							</a>
						</block-wrap>
					</div>
				</div>
			</section>
		</div>

		<template v-if="product && product.companyTnvedName">
			<AppModal v-if="tnvedModal" max-width="530" :closeButton="false" @input="closeModalTnVed">
				<div class="tnvedCodeWrapper">
					<template v-if="product.companyTnvedName[$i18n.locale]">
						{{ product.companyTnvedName[$i18n.locale] }}
					</template>
					<template v-else>
						{{ product.companyTnvedName.uz }}
					</template>
				</div>
			</AppModal>
		</template>

		<!-- 
			planScheduleList
			quantity
			paymentAccountId
			regions
			districts
			hasPrePaid
			prePaidPercent
		 -->

		<!-- districtId -->
		<AppModal v-if="isModalShow" @input="closeModal" v-model="isModalShow" max-width="530">
			<div class="modal__holder">
				<div class="label-input-group mb-30">
					<app-text size="18" weight="600">{{ $t("requestPrice") }}</app-text>
				</div>
				<div class="mb-15 color-text-danger" v-if="product.hasDisabilityInCompany">
					<h4 class="text-center">{{ $t("notification.warning") }}</h4>
					<p>{{ $t("disabilityCompanyText") }}</p>
				</div>
				<div class="modal-form-content">
					<div class="label-input-group">
						<app-multiple-select
							:list="requestData.planScheduleList"
							item-value="id"
							searchable
							@onSearch="searchingPlanSchedule"
							item-text="name"
							:title="$t('plan_schedule')"
							:translatable="true"
							@change="checkPlanScheduleQuantity($event)"
						/>
					</div>
					<div class="label-input-group">
						<form-label
							:title="$t('specifyQuantity')"
							v-model="requestForm.quantity"
							type="number"
							min="0"
							step=".01"
							:is-invalid="$v.requestForm.quantity.$error"
							@input="matchQuantity($event)"
						/>
						<span style="color: crimson; font-size: 12px" v-if="$v.requestForm.quantity.$error"
							>{{ $t("minimumAmount") }}: {{ minPart }}</span
						>
					</div>
					<div class="label-input-group">
						<!-- bankAccountOfBudjetsList -->
						<form-label
							v-if="!getStructureId"
							v-model="requestForm.manBankAccountId"
							:list="bankAccountOfBudjetsList"
							:title="$t('checkingAccount')"
							component-type="dropdown"
							searchable
							@onSearch="searchBankAccount"
							item-value="id"
							item-name="name"
							:is-invalid="$v.requestForm.manBankAccountId.$error"
						/>
						<form-label
							v-if="getStructureId"
							v-model="requestForm.paymentAccountId"
							:title="$t('checkingAccount')"
							component-type="dropdown"
							item-value="id"
							searchable
							@onSearch="searchBankAccountFromRequestData"
							item-name="paymentAccount"
							:list="accountListsOfRequestData"
							:is-invalid="$v.requestForm.paymentAccountId.$error"
						/>
					</div>
					<div>
						<div class="label-input-group">
							<app-multiple-select
								:multiple="false"
								:list="requestData.regions"
								:title="$t('region')"
								:translatable="true"
								:single-item="true"
								@change="pickRegionIds($event)"
								:is-invalid="$v.requestForm.address.$error"
							/>
							<!-- <form-label
											  v-model="requestForm.paymentAccountId"
											  title="Регион"
											  component-type="dropdown"
											  :translatable="true"
											  :list="requestData.regions"
											  @change="pickRegionIds($event)"
											  :is-invalid="$v.requestForm.address.$error"
							/>-->
						</div>

						<!-- <div>
						  <div class="label-input-group">
							<form-label
									v-model="requestForm.expenseId"
									:title="$t('itemOfExpenses')"
									item-value="id"
									component-type="dropdown"
									:list="requestData.expenditureItems"
							/>
						  </div>
						</div>-->
					</div>

					<div>
						<div class="label-input-group">
							<app-multiple-select
								:multiple="false"
								:title="$t('district')"
								:list="requestData.districts"
								:translatable="true"
								:single-item="true"
								@change="pickDistrictIds($event)"
								:selectors="requestForm.address"
								:is-invalid="$v.requestForm.address.$error"
							/>
						</div>
					</div>

					<div v-if="false">
						<div class="label-input-group">
							<form-label
								:title="$t('itemOfExpenses')"
								component-type="dropdown"
								item-value=""
								class="w-100"
								item-name=""
								:list="[]"
							/>
						</div>
					</div>
					<div v-if="getStructureId">
						<div class="label-input-group">
							<form-label
								:isDisabled="Boolean(!getStructureId)"
								:title="$t('typeOfPayment')"
								component-type="dropdown"
								item-value="value"
								class="w-100"
								item-name="title"
								v-model="requestForm.hasPrePaid"
								:list="payStatusList"
								:is-invalid="$v.requestForm.hasPrePaid.$error"
							/>
						</div>
					</div>
					<div class="label-input-group" v-if="requestForm.hasPrePaid">
						<!-- <form-label
							:title="$t('setPrePaidPercent')"
							v-model="requestForm.prePaidPercent"
							type="number"
							min="0"
							:is-invalid="$v.requestForm.prePaidPercent.$error"
						/> -->
						<label class="label-input-text">
							{{ $t("setPrePaidPercent") }}
						</label>
						<div class="fixed-text-block">
							<div class="fixed-text-input">
								<div class="input-fixed">
									<imask-input
										:mask="Number"
										class="app-input"
										:min="1"
										:max="100"
										radix="."
										:class="{ 'is-invalid': $v.requestForm.prePaidPercent.$error }"
										v-model="requestForm.prePaidPercent"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-15">
						<p>
							<span class="text-dark-14-500">
								{{ $t("amountShort") }}:
								<span class="text-dark-14-600">
									{{ requestForm.quantity }}
								</span>
							</span>
						</p>
						<p>
							<span class="text-dark-14-500">
								{{ $t("unitPrice") }}:
								<span class="text-dark-14-600"> {{ product.unitPrice }} </span>
							</span>
						</p>
						<p>
							<span class="text-dark-14-500" style="font-weight: bold">
								{{ $t("totalAmount") }}:

								<span class="text-dark-14-600"> {{ totalAmount }} </span>
							</span>
						</p>
						<p v-if="getStructureId && !product.hasDisabilityInCompany">
							<span class="text-dark-14-500">
								{{ $t("pledgeAmount") }}:
								<span class="text-dark-14-600"> {{ securityDeposit }} </span>
							</span>
						</p>
						<p>
							<span class="text-dark-14-500">
								{{ $t("commissionFee") }}:
								<span class="text-dark-14-600"> {{ operatorDeposit.toFixed(2) }} </span>
							</span>
						</p>
						<p>
							<span class="text-dark-14-500" style="font-weight: bold">
								{{ $t("totalBlockAmount") }}:
								<span class="text-dark-14-600"> {{ correctCalc }} </span>
							</span>
						</p>
						<p>
							<span class="text-dark-14-500" style="font-weight: bold">
								{{ $t("pickup") }}:
								<span class="text-dark-14-600">
									{{ product.isSelfDelivery ? $t("yes") : $t("no") }}
								</span>
							</span>
						</p>
					</div>
				</div>
				<div class="d-flex justify-content-right mt-30">
					<AppButton
						theme="gray-light"
						@click="closeModal()"
						class="mr-10"
						sides="20"
						height="40"
						font-size="14"
						weight="700"
						>{{ $t("cancel") }}
					</AppButton>
					<AppButton
						theme="main"
						class="mr-10"
						sides="20"
						height="40"
						font-size="14"
						:disabled="workingTime"
						weight="700"
						@click="sendRequest()"
						>{{ $t("request") }}
					</AppButton>
				</div>
			</div>
		</AppModal>

		<!-- <AppModal v-if="isModalShowForBudjet" @input="closeModal2" v-model="isModalShowForBudjet" max-width="1000">
			<div class="modal__holder">
				<div class="label-input-group mb-30">
					<app-text size="18" weight="600">{{ $t("requestPrice") }}</app-text>
				</div>
				<div class="modal-form-content">
					<div class="gap-for-2">
						<div>
							<div class="label-input-group">
								<app-multiple-select
									:multiple="false"
									:list="requestData.regions"
									:title="$t('region')"
									:translatable="true"
									:single-item="true"
									@change="pickRegionIds($event)"
									:is-invalid="$v.requestForm.address.$error"
								/>
							</div>
						</div>

						<div>
							<div class="label-input-group">
								<app-multiple-select
									:multiple="false"
									:title="$t('district')"
									:list="requestData.districts"
									:translatable="true"
									:single-item="true"
									@change="pickDistrictIds($event)"
									:selectors="requestForm.address"
									:is-invalid="$v.requestForm.address.$error"
								/>
							</div>
						</div>

						<div>
							<div class="label-input-group">
								<app-multiple-select
									:multiple="false"
									select-type="checkbox"
									:list="bankAccountOfBudjetsList"
									:title="$t('participantAccountInStore')"
									:translatable="false"
									:single-item="true"
									@change="pickBankAccount($event)"
									:is-invalid="$v.allBudgetBlank.bankAccountOfBudjet.$error"
								/>
							</div>
						</div>

						<div>
							<div class="label-input-group">
								<app-selector
									@input="(val) => changingField2(val)"
									@select="(val) => selectedItem2(val)"
									@blur="wasteTypes = []"
									@focus="changingField2('')"
									:value="allBudgetBlank.wasteType"
									:title="$t('itemOfExpenses')"
									:list="wasteTypes"
									:absolute="true"
									listHeight="150"
									:is-invalid="$v.allBudgetBlank.wasteType.$error"
								/>
							</div>
						</div>

						<div>
							<div class="label-input-group">
								<app-multiple-select
									:multiple="false"
									select-type="checkbox"
									:list="activeMonths"
									:title="$t('monthCountOfPayment')"
									:translatable="true"
									:single-item="true"
									@change="pickMonth($event)"
									:is-invalid="$v.allBudgetBlank.numberOfPaymentMonths.$error"
								/>
							</div>
						</div>

						<div>
							<div class="label-input-group">
								<form-label
									v-model="allBudgetBlank.quantity"
									:title="$t('productQuantity')"
									component-type="input"
									:is-invalid="$v.allBudgetBlank.quantity.$error"
									type="number"
									min="0"
								/>
							</div>
						</div>
					</div>

					<div>
						<div class="label-input-group">
							<form-label
								v-model="typeOfUser"
								:title="$t('participantType')"
								component-type="dropdown"
								item-value="value"
								item-name="name"
								:list="typeOfUsers"
							/>
						</div>
					</div>

					<div>
						<div class="label-input-group">
							<app-selector
								@input="(val) => changingField(val)"
								@select="(val) => selectedItem(val)"
								@blur="listOfCodes = []"
								:value="allBudgetBlank.ekdCode"
								:title="$t('search')"
								:list="listOfCodes"
								:blockStyle="true"
								:translatable="true"
								:disabled="!Boolean(Object.keys(allBudgetBlank.wasteType).length)"
								:is-invalid="$v.allBudgetBlank.ekdCode.$error"
							/>
						</div>
					</div>

					<div class="w-100 d-flex justify-content-end">
						<app-button
							@click="postAllBudgetData"
							theme="linear"
							class="px-40 ml-auto font_Hsemibold mt-5"
							:font-size="isMobile ? '12' : '14'"
						>
							{{ $t("send") }}
						</app-button>
					</div>
				</div>
			</div>
		</AppModal> -->

		<SmsChecker @sms="(val) => sendRequest(val)" v-if="smsCheking" @closeModal="smsCheking = false" />

		<loader v-if="isLoading"></loader>
	</main>
</template>
<script>
import SmsChecker from "../../../components/shared-components/SmsChecker.vue";
import AppContentCard from "../../../components/shared-components/AppContentCard";
import AppText from "../../../components/shared-components/AppText";
import AppButtonA from "../../../components/shared-components/AppButtonA";
import BlockWrap from "../../../components/shared-components/BlockWrap";
import AppTabs from "../../../components/shared-components/AppTabs";
import AppImg from "../../../components/shared-components/AppImg";
import DynamicIcon from "../../../components/shared-components/DynamicIcon";
import ProductPreview from "../../../components/pages/ProductList/ProductPreview";
import AppProductCard from "../../../components/shared-components/AppProductCard";
import ShopService from "@/services/api/shop-service";
import TimeService from "@/services/time-service";
import FormLabel from "@/components/shared-components/FormLabel";
import AppMultipleSelect from "@/components/shared-components/AppMultipleSelect";
import CompanyService from "@/services/api/company-service";
import CustomerService from "@/services/api/customer-service";
import ReferenceServer from "@/services/api/reference-service";
import AppButton from "../../../components/shared-components/AppButton";
import { required, integer, requiredIf, between } from "vuelidate/lib/validators";
import AppModal from "../../../components/shared-components/AppModal";
import Loader from "@/components/shared-components/Loader";
import ProductPreviewCarouselVue from "../../../components/pages/ProductList/ProductPreviewCarousel.vue";
import i18n from "../../../plugins/i18n";
import ESignService from "@/services/e-sign-service";
import { correctPrice } from "../../../static/formatter.js";
import AppSelector from "@/components/shared-components/AppSelector";
import months from "@/static/months";
import AppBreadcrumb from "@/components/shared-components/AppBreadcrumb";

export default {
	name: "single-product",
	components: {
		SmsChecker,
		AppModal,
		AppProductCard,
		ProductPreview,
		DynamicIcon,
		AppImg,
		AppTabs,
		BlockWrap,
		AppButtonA,
		AppText,
		AppContentCard,
		FormLabel,
		AppMultipleSelect,
		AppButton,
		Loader,
		ProductPreviewCarouselVue,
		AppSelector,
		AppBreadcrumb,
	},
	data() {
		return {
			smsCheking: false,
			certs: [],
			isIDCard: false,
			tnvedModal: false,
			characteristicsOfTab: [
				{ title: i18n.t("techSpecifications"), active: true },
				{ title: i18n.t("functionalCharacteristics"), active: false },
			],
			payStatusList: [
				{
					title: i18n.t("prePaid"),
					value: true,
				},
				{
					title: i18n.t("unPrePaid"),
					value: false,
				},
			],
			id: null,
			product: {
				offerDeliveryList: [],
				unitPrice: 0,
			},
			similarProducts: [],
			similarProductsQuery: {
				CategoryId: null,
				id: null,
				skip: 0,
				take: 4,
			},
			isModalShow: false,
			requestData: {
				allPlanScheduleList: [],
				planScheduleList: [],
				accountList: [],
				regions: [],
				districts: [],
				expenditureItems: [],
			},
			selectedPlanQuantity: null,
			districts: [],
			requestForm: {
				prePaidPercent: "0.01",
				hasPrePaid: null,
				offerId: null,
				planScheduleId: null,
				quantity: null,
				paymentAccountId: null,
				address: [],
				expenseId: null,
				manBankAccountId: null,
			},
			requestFormClear: {},
			canRequest: false,
			isLoading: false,
			adminUrl: process.env.VUE_APP_ADMIN_URL,
			esignData: {
				datakeys: [],
				model: {
					ecp: null,
				},
				selectData: {},
			},
			eSign: ESignService,
			isMinimum: true,
			shelfLifeTypes: [
				{
					value: 1,
					name: this.$i18n.t("days"),
				},
				{
					value: 2,
					name: this.$i18n.t("month"),
				},
			],
			isModalShowForBudjet: false,
			typeOfUsers: [
				{
					name: "User type 1",
					value: 1,
				},
			],
			typeOfUser: null,
			bankAccountOfBudjetsList: [],
			wasteTypes: [],
			wasteTypesMain: [],
			listOfCodes: [],
			listOfCodesMain: [],
			months,
			timeoutForSearch: null,
			quantity: null,
			allBudgetBlank: {
				quantity: null,
				ekdCode: {},
				wasteType: {},
				numberOfPaymentMonths: null,
				bankAccountOfBudjet: null,
			},
			activeMonths: [],
			accountListsOfRequestData: [],
		};
	},
	created() {
		this.getId();
		this.getProductById();
		this.requestFormClear = JSON.parse(JSON.stringify(this.requestForm));
		this.getActiveMonths();
	},
	methods: {
		searchingPlanSchedule(val) {
			this.requestForm.planScheduleId = null;
			this.selectedPlanQuantity = null;
			if (!val) {
				this.requestData.planScheduleList = this.requestData.allPlanScheduleList;
			} else {
				this.requestData.planScheduleList = this.requestData.allPlanScheduleList.filter((el) =>
					el.expCodeName.code.includes(val)
				);
			}
		},
		searchBankAccountFromRequestData(val) {
			this.requestForm.paymentAccountId = null;
			if (!val) {
				this.accountListsOfRequestData = this.requestData.accountList;
				return;
			}
			let result = this.requestData.accountList.filter(
				(e) => e.paymentAccount.includes(val) || e.paymentAccount.toLowerCase().includes(val)
			);
			this.accountListsOfRequestData = result;
		},
		async searchBankAccount(val) {
			this.getBudjetAccounts(val);
		},
		getActiveMonths() {
			this.activeMonths = this.months.filter((e) => e.id >= new Date().getMonth() + 1);
		},
		async postAllBudgetData() {
			try {
				this.$store.commit("setLoadingStatus", true);
				let signHash = await this.$eimzo.signWithKey();
				let data = {
					planScheduleId: this.requestForm.planScheduleId,
					manAccountId: this.requestForm.manBankAccountId,
					offerId: this.product.id,
					regionId: this.requestForm.address[0].regionId,
					districtId: this.requestForm.address[0].district[0].districtId,
					quantity: this.requestForm.quantity,
					signHash,
				};
				const response = await this.$api.post("api-budget/Budget/RequestEtp", data);
				this.$store.commit("setLoadingStatus", false);
				this.closeModal();
				this.$notification.success(this.$t("sendedRequestToDMBAt"));
			} catch (error) {
				if (error === "Failed") {
					this.$notification.error(this.$t("eSignKeyError"));
				}
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		async getListOfSearchings(val) {
			clearTimeout(this.timeoutForSearch);
			if (!this.allBudgetBlank.wasteType.value || !val) return;
			this.timeoutForSearch = setTimeout(async () => {
				let params = { expCode: this.allBudgetBlank.wasteType.value, productName: val };
				const response = await this.$api.get("api-budget/Budget/GetByExpGoods", { params });
				let data = [];
				response.data.result.data.forEach((e) => {
					e.name.uz = `${e.code} - ${e.name.uz}`;
					e.name.ru = `${e.code} - ${e.name.ru}`;
					e.name.cyrl = `${e.code} - ${e.name.cyrl}`;
					data.push({
						name: e.name,
						value: e.enktId,
						id: e.manExpGoodId,
					});
				});
				this.listOfCodes = data;
			}, 300);
		},
		pickMonth(e) {
			if (!e) {
				this.allBudgetBlank.numberOfPaymentMonths = null;
				return;
			} else {
				this.allBudgetBlank.numberOfPaymentMonths = e;
			}
		},
		smsSended(sms) {
			console.log(sms);
		},
		priceCorrecter(str) {
			return correctPrice(String(str));
		},
		currentLanguageWarrantyTerms(data) {
			return data[`${this.$store.state.clng}`] ? data[`${this.$store.state.clng}`] : data.uz;
		},
		async signWithKey(code) {
			try {
				this.$store.commit("setLoadingStatus", true);
				let signHash = await this.$eimzo.signWithKey();
				await this.sign(signHash, code);
			} catch (err) {
				this.$notification.error(this.$t("eSignKeyError"));
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		async newSign(code) {
			// console.log(code);
			// let usersList = await this.$eimzo.listAllUserKeys();
			// let res = usersList.find(
			// 	(item) => item.esiId.toUpperCase() === this.$store.state.userInfo.esiId.toUpperCase()
			// );
			await this.signWithKey(code);
		},
		closeModalTnVed() {
			this.tnvedModal = false;
		},
		getId() {
			if (this.$route.params.id) {
				this.offerNumber = this.$route.params.id;
			}
		},
		checker() {
			if (this.product.photos[0] === ";" || this.product.photos[0] === "|") {
				this.product.photos = this.product.photos.substring(1);
				this.checker();
			} else return;
		},
		getProductById(offerNumber) {
			let productId = this.offerNumber;
			if (offerNumber) productId = offerNumber;

			ShopService.getByOffer(productId).then(
				(response) => {
					this.product = response.data.result;
					// this.canRequest = this.product.statusId === 7;
					this.product.photos = `${this.product.photos}`;
					this.checker();
					this.getSimilarProducts();
					this.checkForRequest();
				},
				(error) => {
					console.error("error", error);
				}
			);
		},
		getSimilarProducts() {
			this.similarProductsQuery.CategoryId = this.product.categoryId;
			this.similarProductsQuery.id = this.product.id;

			ShopService.getSimilarProducts(this.similarProductsQuery).then(
				(response) => {
					this.similarProducts = response.data.result.data;
				},
				(error) => {
					console.log("error", error);
				}
			);
		},
		async sendRequest(code) {
			try {
				this.requestForm.offerId = +this.product.id;
				this.requestForm.quantity = +this.requestForm.quantity;

				this.$v.requestForm.$reset();
				this.$v.requestForm.$touch();
				if (this.$v.requestForm.$invalid) {
					this.$notify({ type: "error", text: "Поля не заполнены полными!" });
					return;
				}

				if (!code) {
					await this.sendValidationCode();
					return;
				}

				this.requestForm.planScheduleId = this.requestForm.planScheduleId || 0;

				if (this.getStructureId) {
					await this.newSign(code);
				} else {
					await this.postAllBudgetData();
				}
			} catch (err) {
				this.$notify({ type: "error", text: err });
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		async sendValidationCode() {
			try {
				this.$store.commit("setLoadingStatus", true);
				const response = await this.$api.get("/api-company/Company/ConfirmActionWithSmsCode?otpType=4");
				if (response.data.result.confirmedWithOtp) {
					this.smsCheking = true;
				} else {
					this.$store.commit("setLoadingStatus", false);
					await this.sendRequest(true);
				}
			} catch (err) {
				this.$notify({ type: "error", text: err.message });
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		setESign() {
			setTimeout(() => {
				this.eSign.callbackFunction = this.getESignData;
				console.log("keys;", [this.eSign.datakeys, this.$store.state.userInfo]);
				this.eSign.setESignKeys(this.$store.state.userInfo);
			}, 1000);
		},
		getESignData() {
			this.esignData.selectData = this.eSign.selectData;
			this.sign();
		},
		async sign(signHash, code) {
			this.isLoading = true;
			let data = { ...this.requestForm, signHash };
			if (typeof code === "string") {
				data.otpCode = code;
			}
			try {
				this.$store.commit("setLoadingStatus", true);
				const response = await ShopService.addRequest(data);
				if (response.data.result.success) {
					this.$notify({ type: "success", text: "Запрос отправлен!" });
					this.clearForm();
					this.getProductById();
					this.closeModal();
				} else {
					this.$notify({ type: "error", text: "Запрос отправлен!" });
				}
				this.$store.commit("setLoadingStatus", false);
			} catch (error) {
				this.$store.commit("setLoadingStatus", false);
				this.$notify({ type: "error", text: error });
			} finally {
				this.$store.commit("setLoadingStatus", false);
				this.isLoading = false;
			}
		},
		getMonthById(id) {
			let month = this.months.find((e) => e.id === id);
			return month ? month.name[this.$store.state.clng] : "MonthName";
		},
		async getPlanScheduleList() {
			this.requestData.planScheduleList = this.requestData.allPlanScheduleList;
			if (this.requestData.allPlanScheduleList.length > 0) return;
			const response = await CustomerService.getListPlanProducts();
			let res = [];
			res = response.data.result.data;
			res.forEach((el) => {
				if (!el.product.name) {
					el.product.name = { uz: "Product name", ru: "Product name", cyrl: "Product name" };
				}
				for (let i in el.product.name) {
					if (!el.name) {
						el.name = {};
					}
					el.name[i] =
						el.expCodeName && el.expCodeName.code
							? `${el.expCodeName.code} - ${el.product.name[i]} (${this.getMonthById(el.month)} - ${
									el.productQuantity
							  } ${el.measureName})`
							: `${el.product.name[i]} (${this.getMonthById(el.month)} - ${el.productQuantity} ${
									el.measureName
							  })`;
				}
			});
			this.requestData.allPlanScheduleList = res;
			this.requestData.planScheduleList = res;
		},
		getAccountList() {
			if (this.requestData.accountList.length > 0 || !this.getStructureId) {
				this.accountListsOfRequestData = this.requestData.accountList;
				return;
			}
			CompanyService.getListAccount().then(
				(response) => {
					this.requestData.accountList = response.data.result;
					this.accountListsOfRequestData = response.data.result;
					if (this.requestData.accountList.length === 1) {
						this.requestForm.paymentAccountId = this.requestData.accountList[0]["id"];
					}
				},
				(error) => {
					console.log(error);
				}
			);
		},
		getRegions() {
			if (this.requestData.regions.length > 0) return;
			ReferenceServer.getRegions().then(
				(response) => {
					//   this.requestData.regions = response.data.result.data;
					this.getRegionsByProductRegions(response.data.result.data);
				},
				(error) => {
					console.log(error);
				}
			);
		},
		getDistricts() {
			if (this.requestData.districts.length > 0) return;
			ReferenceServer.getDistricts().then(
				(response) => {
					this.districts = response.data.result.data;
				},
				(error) => {
					console.log(error);
				}
			);
		},
		getRegionsByProductRegions(regions) {
			this.requestData.regions = [];
			this.product.offerDeliveryList.forEach((item) => {
				let filteredRegions = regions.filter((region) => region.id === item.regionId);
				if (this.requestData.regions.length === 0) {
					this.requestData.regions = filteredRegions;
				} else {
					this.$options.filters.mergeArrays(this.requestData.regions, filteredRegions);
				}

				this.requestData.regions = this.$options.filters.removeDuplicates(this.requestData.regions);
			});
		},
		getExpenditureItems() {
			if (this.requestData.expenditureItems.length > 0) return;
			ShopService.getExpenditureItems().then(
				(response) => {
					this.requestData.expenditureItems = response.data.result;
				},
				(error) => {
					this.$notification.error(error);
				}
			);
		},
		checkForRequest() {
			ShopService.checkRequest(this.product.id).then(
				(response) => {
					this.canRequest = response.data.result.success;
				},
				(error) => {
					this.$notify({ type: "error", text: error });
				}
			);
		},
		pickRegionIds(e) {
			this.requestForm.address = [];
			// this.pickRegionId(e);
			this.addRegionToForm(e);
			this.requestForm.address[0].district = [];
			this.requestData.districts = [];
			this.requestData.districts = this.districts.filter((each) => each.regionId === e);
		},
		pickRegionId(region) {
			const index = this.findRegionIndex(region.id);
			if (index === -1) {
				this.addRegionToForm(region);
				this.addDistrictsToList(region);
			} else {
				this.removeRegionFromForm(index);
				this.removeDistrictsFromList(region);
			}
			if (this.requestData.regions.length === 0) {
				this.requestData.districts = [];
			}
		},
		pickDistrictIds(e) {
			this.requestForm.address[0].district = [];
			this.requestForm.address[0].district.push({
				districtId: e,
			});
			return;
		},
		pickDistrictId(e) {
			const index = this.findRegionIndex(e.regionId);
			const selectedRegion = this.requestForm.address[index];
			const indexDistrict = this.findDistrictIndex(e, selectedRegion);

			if (indexDistrict === -1) {
				this.addDistrictsToForm(e, selectedRegion);
			} else {
				this.removeDistrictsFromForm(e, selectedRegion);
			}
			this.requestForm.address[index] = selectedRegion;
		},
		async openModal() {
			this.isModalShow = true;
			if (this.getStructureId) {
				await this.getAccountList();
			} else {
				await this.getBudjetAccounts();
			}
			await this.getPlanScheduleList();
			await this.getRegions();
			await this.getDistricts();
			// await this.getExpenditureItems();
		},
		closeModal() {
			this.isModalShow = false;
			this.requestForm = {
				prePaidPercent: "0.01",
				hasPrePaid: null,
				offerId: null,
				planScheduleId: null,
				quantity: null,
				paymentAccountId: null,
				address: [],
				expenseId: null,
				manBankAccountId: null,
			};
			this.$v.requestForm.$reset();
		},
		clearForm() {
			this.requestForm = this.requestFormClear;
			this.$v.requestForm.$reset();
		},
		addRegionToForm(region) {
			this.requestForm.address.push({
				regionId: region,
				district: [],
			});
		},
		removeRegionFromForm(index) {
			this.requestForm.address = this.requestForm.address.filter((element, id) => id !== index);
		},
		addDistrictsToList(region) {
			const filteredDistricts = this.districts.filter((district) => district.regionId === region.id);

			if (this.requestData.districts.length === 0) {
				this.requestData.districts = filteredDistricts;
			} else {
				this.$options.filters.mergeArrays(this.requestData.districts, filteredDistricts);
			}
		},
		removeDistrictsFromList(region) {
			this.requestData.districts = this.requestData.districts.filter(
				(district) => district.regionId !== region.id
			);
		},
		addDistrictsToForm(district, formRegion) {
			formRegion.district.push({
				districtId: district.id,
			});
		},
		removeDistrictsFromForm(district, formRegion) {
			formRegion.district = formRegion.district.filter((d) => d.districtId !== district.id);
		},
		findRegionIndex(regionId) {
			return this.requestForm.address.findIndex((region) => region.regionId === regionId);
		},
		findDistrictIndex(district, selectedRegion) {
			return selectedRegion.district.findIndex((d) => d.districtId === district.id);
		},
		checkPlanScheduleQuantity(e) {
			this.requestForm.planScheduleId = e.id;
			this.selectedPlanQuantity = e.productQuantity;
		},
		matchQuantity(val) {
			// if (!this.selectedPlanQuantity && this.$store.state.userInfo.isCorporate) {
			// 	this.$notify({
			// 		type: "error",
			// 		title: "Для начала выберите любой тарифный план!",
			// 	});
			// 	this.requestForm.quantity = null;
			// 	return;
			// }
			// const status = this.selectedPlanQuantity <script parseInt(val);
			// if (status && this.$store.state.userInfo.isCorporate) {
			// 	this.qunatityMatchedStatus = status;
			// 	this.$notify({
			// 		type: "error",
			// 		title: `Количество планового графика больше, чем у продукта! (${this.minPart} - ${this.maxPart})`,
			// 	});
			// 	this.requestForm.quantity = null;
			// 	return;
			// }
		},
		setTab(index) {
			this.characteristicsOfTab.forEach((elem) => {
				elem.active = false;
			});
			this.characteristicsOfTab[index].active = true;
		},
		closeModal2() {
			this.isModalShowForBudjet = false;
			this.closeModal();
			this.listOfCodesMain = this.listOfCodes = this.wasteTypes = this.wasteTypesMain = [];
			this.allBudgetBlank.ekdCode = {};
			this.allBudgetBlank.quantity = null;
			this.allBudgetBlank.wasteType = {};
		},
		async getBudjetAccounts(val) {
			let params = {};
			val ? (params.search = val) : null;
			const response = await this.$api("api-budget/Budget/GetByAccounts", { params });
			let data = [];
			response.data.result.data.forEach((elem) => {
				if (elem.name) {
					data.push({
						name: `${elem.licass} - ${elem.organ} (${elem.name})`,
						value: `${elem.licass} | ${elem.organ}`,
						id: elem.id,
					});
				} else {
					data.push({
						name: `${elem.licass} - ${elem.organ}`,
						value: `${elem.licass} | ${elem.organ}`,
						id: elem.id,
					});
				}
			});
			this.bankAccountOfBudjetsList = data;
		},
		async getAllExpenses() {
			// const response = await this.$api.get("api-budget/Budget/GetAllManExpenses");
			// let data = [];
			// response.data.result.data.forEach((elem) => {
			// 	data.push({
			// 		name: `${elem.code} - ${elem.name}`,
			// 		value: elem.code,
			// 	});
			// });
			// this.wasteTypesMain = JSON.parse(JSON.stringify(data));
			// this.wasteTypes = [];
		},
		async openModal2() {
			this.isModalShowForBudjet = true;
			this.getBudjetAccounts();
			this.getAllExpenses();
			await this.getRegions();
			await this.getDistricts();
		},
		selectedItem(val) {
			this.allBudgetBlank.ekdCode = JSON.parse(JSON.stringify(val));
			this.listOfCodes = [];
		},
		async changingField(val) {
			if (this.allBudgetBlank.ekdCode.name !== val) {
				this.allBudgetBlank.ekdCode = {};
				this.listOfCodes = [];
			}
			if (!val) {
				this.listOfCodes = [];
			}
			await this.getListOfSearchings(val);
		},
		selectedItem2(val) {
			this.allBudgetBlank.wasteType = JSON.parse(JSON.stringify(val));
			this.wasteTypes = [];
			if (!Object.keys(this.allBudgetBlank.wasteType).length) {
				this.allBudgetBlank.ekdCode = {};
			}
		},
		changingField2(val) {
			if (this.allBudgetBlank.wasteType.name !== val) {
				this.allBudgetBlank.wasteType = {};
				this.wasteTypes = [];
			}
			if (!val) {
				this.wasteTypes = [];
			}

			this.wasteTypes = JSON.parse(
				JSON.stringify(
					this.wasteTypesMain.filter(
						(elem) => elem.name.toLowerCase().includes(val) || elem.name.includes(val)
					)
				)
			);
			if (!Object.keys(this.allBudgetBlank.wasteType).length) {
				this.allBudgetBlank.ekdCode = {};
			}
		},
		pickBankAccount(e) {
			if (!e) this.allBudgetBlank.bankAccountOfBudjet = null;
			this.allBudgetBlank.bankAccountOfBudjet = e;
		},
		breadCrumbList() {
			if (this.product?.category?.id && this.product?.category?.name) {
				return [
					{ label: this.$t("home"), url: "/" },
					{ label: this.$t("eShop"), url: "/product-list" },
					{
						label: this.$options.filters.translate(this.product?.category?.name),
						url: "/product-list/category/" + this.product?.category?.id,
					},
				];
			} else {
				return [
					{ label: this.$t("home"), url: "/" },
					{ label: this.$t("eShop"), url: "/product-list" },
				];
			}
		},
		checkBrand(item) {
			const condition = this.$options.filters.translate(item);
			if (condition === "") {
				return false;
			} else {
				return true;
			}
		},
		checkProperties(item) {
			return item.some((e) => e.isSelected);
		},
		getSelectedOption(item) {
			return item.filter((e) => e.isSelected)[0]?.values;
		},
		getMonthNameAndYear(dateString) {
			const date = new Date(dateString);
			const monthIndex = date.getMonth(); // 0-11
			const year = date.getFullYear();
			const month = months[monthIndex];
			return {
				en: `${year} ${month.name.en}`,
				ru: `${year} ${month.name.ru}`,
				uz: `${year} ${month.name.uz}`,
				cyrl: `${year} ${month.name.cyrl}`,
			};
		},
	},
	computed: {
		getStructureId() {
			return ["5", "10"].includes(localStorage.structure_id) ? false : true;
		},
		totalAmount() {
			let price = this.product.unitPrice;
			let quantity = this.requestForm.quantity;
			return price * quantity;
		},
		securityDeposit() {
			let percent = 1;
			let totalAmount = this.totalAmount;
			let result = (totalAmount * percent) / 100;
			return result;
		},
		operatorDeposit() {
			let percent = 0.1;
			let totalAmount = this.totalAmount;
			let result = (totalAmount * percent) / 100;
			return result;
		},
		correctCalc() {
			let a = this.getStructureId ? this.securityDeposit : 0;
			let b = this.operatorDeposit;
			let operator = "+";
			a = String(a);
			b = String(b);
			let result, aPointLength, bPointLength, larger;
			aPointLength = a.split(".")[1]?.length;
			bPointLength = b.split(".")[1]?.length;
			if (aPointLength && bPointLength) {
				larger = aPointLength > bPointLength ? aPointLength : bPointLength;
			} else {
				larger = aPointLength || bPointLength || 0;
			}
			let kopayadiganSon = Math.pow(10, larger);

			a = a * kopayadiganSon;
			b = b * kopayadiganSon;

			if (operator === "+") {
				result = (+a + +b) / kopayadiganSon;
			} else if (operator === "-") {
				result = (a - b) / kopayadiganSon;
			} else if (operator === "*") {
				result = (a * b) / kopayadiganSon / kopayadiganSon;
			} else if (operator === "/") {
				result = a / b / kopayadiganSon / kopayadiganSon;
			}
			return result;
		},
		getExpiringYears() {
			return TimeService.getTimeDiff(this.product.createdDate, this.product.expirationDate);
		},
		deliveriesList() {
			if (this.product.offerDeliveryList) {
				let offerDeliveryList = [...this.product.offerDeliveryList];
				const groupDeliveryList = this.$R.groupBy((deliveryList) => {
					return this.$options.filters.translate(deliveryList.regionName);
				});
				if (this.isMinimum && offerDeliveryList.length > 10) {
					offerDeliveryList.length = 10;
				}
				return groupDeliveryList(offerDeliveryList);
			}
		},
		minPart() {
			return this.product.minPart;
		},
		maxPart() {
			return this.product.maxPart;
		},
		url() {
			return process.env.VUE_APP_BASE_URL_DOMAIN;
		},
		workingTime() {
			return !Boolean(this.$store.state.isWorkingHour && this.$store.state.isWorkingDay);
		},
		tabOptions() {
			if (this.product?.functionalCharacteristics && !this.product?.companyEnktCode) {
				return this.characteristicsOfTab;
			} else {
				return [{ title: i18n.t("techSpecifications"), active: true }];
			}
		},
	},
	validations: {
		requestForm: {
			hasPrePaid: { required },
			offerId: { required, integer },
			planScheduleId: {
				required: requiredIf(function () {
					return !this.getStructureId;
				}),
				// function() {
				// 	return this.requestForm.planScheduleId || !this.$store.state.userInfo.isCorporate;
				// }
			},
			prePaidPercent: {
				required: requiredIf(function () {
					return this.requestForm.hasPrePaid === true;
				}),
				between(value) {
					return between(0.01, 100)(value);
				},
				// minValue: function (val) {
				// 	return Number(val) >= 0.01;
				// },
				// maxValue: function (val) {
				// 	return Number(val) <= 100;
				// },
			},
			quantity: {
				// between(value) {
				// 	return between(this.minPart, this.maxPart)(value);
				// },
				minValue: function (val) {
					// console.log([Number(val) >= this.minPart, val, Number(val), parseInt(val), this.minPart]);
					return Number(val) >= this.minPart;
				},
				required,
			},
			manBankAccountId: {
				required: requiredIf(function () {
					return !this.getStructureId;
				}),
				integer,
			},
			paymentAccountId: {
				required: requiredIf(function () {
					return this.getStructureId;
				}),
				integer,
			},
			address: {
				required,
				$each: {
					regionId: {
						required,
						integer,
					},
					district: {
						required,
						$each: {
							districtId: {
								required,
								integer,
							},
						},
					},
				},
			},
			// expenseId: {required, integer}
		},
		// postBudget: {
		// 	regionId: {
		// 		required: function () {
		// 			return Boolean(
		// 				!this.requestForm.address ||
		// 					Array.isArray(this.requestForm.address) ||
		// 					this.requestForm.address[0].regionId
		// 			);
		// 		},
		// 		districtId: {
		// 			required: function () {
		// 				return Boolean(!this.requestForm.address[0].district[0].districtId);
		// 			},
		// 		},
		// 		month: {
		// 			required: function () {
		// 				return Boolean(!this.numberOfPaymentMonths);
		// 			},
		// 		},
		// 		manAccountId: {
		// 			required: function () {
		// 				return Boolean(!this.bankAccountOfBudjet);
		// 			},
		// 		},
		// 		manExpGoodId: {
		// 			required: function () {
		// 				return Boolean(!this.ekdCode.id);
		// 			},
		// 		},
		// 		quantity: {
		// 			required: function () {
		// 				return Boolean(!this.quantity);
		// 			},
		// 		},
		// 	},
		// },
		allBudgetBlank: {
			// wasteType: {
			// 	required,
			// },
			// ekdCode: {
			// 	required,
			// },
			quantity: {
				minValue: function (val) {
					return Number(val) >= this.minPart;
				},
				required,
			},
			numberOfPaymentMonths: {
				required,
			},
			bankAccountOfBudjet: {
				required,
			},
		},
	},
	watch: {
		isModalShow(val) {
			if (val) {
				if (!this.getStructureId) {
					this.requestForm.hasPrePaid = false;
				}
				this.requestForm.address = [];
				this.requestData.districts = [];
			}
		},
		"requestForm.prePaidPercent"(val) {
			if (!this.requestForm.hasPrePaid) {
				this.requestForm.prePaidPercent = "0.01";
			}
			if (Number(val) > 100) {
				this.requestForm.prePaidPercent = "100";
			}
		},
		"requestForm.hasPrePaid"() {
			if (!this.requestForm.hasPrePaid) {
				this.requestForm.prePaidPercent = "0.01";
			}
		},
		"requestForm.quantity"(val) {
			let value = String(val);
			if (value.includes(".")) {
				let data = value;
				let secondValue = data.split(".")[1];
				if (secondValue.length > 2) {
					this.requestForm.quantity = data.substring(0, value.length - 1);
				}
			}
		},
		isModalShowForBudjet() {
			this.$v.allBudgetBlank.$reset();
		},
	},
	async mounted() {
		await this.$eimzo.install();
	},
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";
.gap-for-2 {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
}
.textSalToqroq {
	color: #5a7288 !important;
}
.flexBetween {
	width: 100%;
	display: flex !important;
	justify-content: space-between !important;
}
.vh--holder picture {
	height: 100% !important;
}
picture img {
	height: 100% !important;
	object-fit: cover !important;
}
p.color-text-light span {
	display: block;
	max-width: 300px !important;
	overflow: hidden;
	overflow-wrap: break-word;
}
.tnvedCodeWrapper {
	background: #fff;
	padding: 20px;
}
.tnvedCoed {
	display: flex;
	gap: 10px;
	align-items: center;
}
.showTnVed {
	fill: #ccc;
	height: 20px;
	cursor: pointer;
}
.imgContainerPreview {
	max-width: 600px;
}
.tabContentWrapper {
	margin-top: 22px;
	font-size: 0.9rem;
	& > div {
		text-align: justify;
		max-width: 100%;
		overflow-wrap: break-word;
	}
}
.tabHeaders {
	display: flex;
	gap: 10px;
	user-select: none;
	& > div {
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		border-bottom: 2px solid #ffffff;
	}
	& > div.active {
		border-bottom: 2px solid #5575a8;
	}
}
.tabContent {
	margin-top: 20px;
	background: #ffffff;
	border-radius: 10px;
	border: 1px solid #efefef;
	padding: 35px;
}
.product-description {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;

	&__info {
		width: #{size(618px)};
	}

	&__image {
		width: #{size(430px)};
	}
}
.modal-form-content {
	display: grid;
	grid-template-columns: 100%;
	grid-row-gap: 15px;
	.grid-row {
		display: grid;
		grid-template-columns: calc(50% - 8px) calc(50% - 8px);
		grid-column-gap: 15px;
		justify-content: space-between;
	}
	.grid-row-extra {
		display: grid;
		grid-template-columns: 175px calc(100% - 190px);
		grid-column-gap: 15px;
		justify-content: space-between;
	}
}
.product-info__actions {
	margin-left: 50px;
	display: flex;
	width: 100%;
	.app-content-card {
		max-width: 350px;
	}
}
.table-block {
	tbody {
		tr:nth-child(2n) {
			background-color: #f3f8fc;
		}
	}
}
.product-info {
	display: flex;
	flex-wrap: wrap;
	padding-top: 30px;
	align-items: flex-start;
	&__preview {
		margin-top: -30px;
		width: #{size(470px)};
	}
	&__data {
		width: #{size(289px)};
	}
	&__actions {
		width: 100%;
		padding: 30px 40px;
	}
	&__box {
		max-width: 350px;
		min-width: 300px;
	}
	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	@media (max-width: $m-desktop-small) {
		flex-wrap: wrap;
		&__preview {
			width: calc(55% - 30px);
			margin-left: auto;
			margin-right: auto;
		}
		&__data {
			width: 100%;
			margin-top: 40px;
			order: 1;
		}
		&__actions {
			width: 100%;
			.app-content-card {
				width: 80%;
				margin-left: auto;
				margin-right: auto;
				margin-top: 30px;
			}
		}
	}
}
.header-title {
	color: #341f47;
	font-weight: 500 !important;
}
.product-carousel-container {
	.product-carousel-img {
		background-color: white;
	}
}
.regions-list {
	display: flex;
	flex-direction: column;
	.region {
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.characteristics {
	.text {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
		span {
			color: black;
			margin-left: 3px;
		}
	}
}

@media (max-width: 768px) {
	.product-description__info {
		width: 100%;
	}
	.product-description__image {
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		max-width: 400px;
		margin-top: 30px;
	}
}
@media (max-width: 400px) {
	.product-info__actions {
		.app-content-card {
			width: 100%;
		}
	}
}
</style>
