<template>
	<div v-click-outside="closeTree" :class="{ relativePosition: absolute }">
		<p v-if="title" class="text form-label__title text--weight-400" style="font-size: 12px; line-height: 16px">
			{{ title }}
		</p>
		<label class="app-input">
			<input
				v-if="value.name"
				type="text"
				:value="value.name"
				@keyup="$emit('input', $event.target.value)"
				class="base-input app-input__field"
			/>
			<input
				v-else
				type="text"
				@keyup="$emit('input', $event.target.value)"
				class="base-input app-input__field"
			/>
		</label>
		<div
			v-if="Array.isArray(list) && list.length"
			:class="{ absoluteReplacing: absolute }"
			class="custonFieldForEnkdCodes my-4"
			:style="{ maxHeight: `${listHeight}px` }"
		>
			<template v-for="item in list">
				<p @click="selectedItem(item)">{{ item.name | translate }}</p>
			</template>
		</div>
	</div>
</template>
<script>
import vClickOutside from "v-click-outside";
export default {
	inheritAttrs: false,
	directives: { clickOutside: vClickOutside.directive },
	props: {
		value: {
			default: {
				name: null,
				value: null,
			},
		},
		title: {
			type: String,
		},
		list: {
			default: [],
			// name and value
		},
		absolute: {
			type: Boolean,
			default: false,
		},
		listHeight: {
			type: String | Number,
			default: 200,
		},
	},
	data: () => ({
		valueDefault: null,
	}),
	methods: {
		selectedItem(value) {
			this.$emit("select", value);
		},
		closeTree() {
			this.$emit("blur");
		},
	},
};
</script>

<style scoped lang="scss">
.relativePosition {
	position: relative;
}

.absoluteReplacing {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 1;
}
.app-input {
	border-radius: 8px;
}
.base-input {
	padding: 10px 15px;
	border-radius: 10px;
}
.custonFieldForEnkdCodes {
	margin-top: 5px;
	border-radius: 8px;
	background: #f8f8f8;
	overflow-y: auto;
	padding: 5px;
	& > p {
		padding: 10px 5px;
	}
	& > p:hover {
		background: #fff;
		cursor: pointer;
	}
}
</style>

